<template>
  <main>
    <section class="js-page page join">
      <div class="page-content">Edit user setting Make it look good</div>
    </section>
  </main>
</template>

<script >
import { usePerms } from "@/logic/usePerms"
import axios from "axios"
import { computed, defineComponent, ref } from "vue"
import { useStore } from "vuex"
import Button from "../components/Button.vue"

export default defineComponent({
  name: "Dashboard",
  components: {
    Button,
  },
  props: {
    pid: String,
  },
  setup(props) {
    const store = useStore()
    const { hasPerm, checkToken } = usePerms()

    const token = computed(() => store.getters["auth/token"])
    let oproject = {}
    const project = ref()
    const url = "http://localhost:8085"
    // const url = "https://api.otonomi.no"

    const loadProjects = async () => {
      if (!store.getters["auth/isLogedIn"]) {
        store.commit("setModal", 2)
        return
      }
      await checkToken()

      axios({
        baseURL: url,
        url: "/project/" + props.pid,
        data: {},
        method: "GET",
        headers: {
          Authorization: "Bearer " + token.value,
        },
      })
        .then((resp) => {
          console.log(resp)
          oproject = resp.data
          project.value = resp.data
        })
        .catch((err) => {
          console.log(err)
        })
    }

    const updateproject = async (property, e) => {
      if (!store.getters["auth/isLogedIn"]) {
        store.commit("setModal", 2)
        return
      }
      await checkToken()

      axios({
        baseURL: url,
        url: "/project/" + project.value.id,
        data: {
          [property]: project.value[property],
        },
        method: "PATCH",
        headers: {
          Authorization: "Bearer " + token.value,
        },
      })
        .then((resp) => {
          console.log(resp)
        })
        .catch((err) => {
          console.log(err)
        })
    }
    const newJob = async () => {
      if (!store.getters["auth/isLogedIn"]) {
        store.commit("setModal", 2)
        return
      }
      await checkToken()

      axios({
        baseURL: url,
        url: "/job/" + project.value.id,
        data: {},
        method: "POST",
        headers: {
          Authorization: "Bearer " + token.value,
        },
      })
        .then((resp) => {
          console.log(resp)

          project.value = resp.data

          // projects.value = p
        })
        .catch((err) => {
          console.log(err)
        })
    }
    const updatejob = async (property, value, jid) => {
      if (!store.getters["auth/isLogedIn"]) {
        store.commit("setModal", 2)
        return
      }
      await checkToken()

      axios({
        baseURL: url,
        url: "/job/" + jid,
        data: {
          [property]: value,
        },
        method: "PATCH",
        headers: {
          Authorization: "Bearer " + token.value,
        },
      })
        .then((resp) => {
          console.log(resp)
        })
        .catch((err) => {
          console.log(err)
        })
    }

    // loadProjects()
    return {
      project,
      updateproject,
      updatejob,
      newJob,
    }
  },
})
</script>

<style lang="scss" scoped>
// @import "../css/join.scss";

.page-content {
  width: 800px;
  margin: 300px auto 0;
}
.progress-bar {
  height: 4px;
  width: 100%;
  overflow: hidden;
  border-radius: 2px;
  background-color: #dadff3;
  margin: 6px 0;

  .progress {
    height: 100%;
    width: 40%;
    background-color: #4bc0dd;
    display: block;
  }
}

.searched-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-animation: slideY 0.6s both;
  animation: slideY 0.6s both;

  .searched-show {
    font-size: 19px;
    font-weight: 600;
  }
  .searched-sort {
    font-size: 14px;
    color: var(--color-text-light);
    .post-time {
      font-weight: 600;
      color: var(--color-text-light);
    }
    .menu-icon {
      font-size: 9px;
      color: var(--color-text-light);
      margin-left: 6px;
    }
  }
}

.job-cards {
  text-align: left;
  width: 100%;
  padding-top: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 25px;
  grid-row-gap: 25px;
  -webkit-animation: slideY 0.6s both;
  animation: slideY 0.6s both;

  .job-card {
    background-color: white;
    border-radius: 8px;
    cursor: pointer;
    transition: 0.2s;
    overflow: hidden;

    &.new-job {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: var(--color-blue);
      box-shadow: var(--shadow-dark);
      color: white;

      .symbol {
        font-size: 4rem;
        font-weight: 700;
      }
    }

    &:hover {
      transform: scale(1.02);
    }

    img {
      width: 100%;
    }

    .job-card-header {
      display: flex;
      align-items: flex-start;

      svg {
        width: 46px;
        padding: 10px;
        border-radius: 8px;
      }
    }
    .menu-dot {
      background-color: var(--color-text-light);
      box-shadow: -6px 0 0 0 var(--color-text-light),
        6px 0 0 0 var(--color-text-light);
      width: 4px;
      height: 4px;
      border: 0;
      padding: 0;
      border-radius: 50%;
      margin-left: auto;
      margin-right: 8px;
    }
    .job-card-wrapper {
      padding: 5px 16px 20px 16px;
    }
    .job-card-time {
      color: var(--color-text-light);
      font-weight: 400;
      margin-top: 16px;
      font-size: 0.9rem;
    }
    .job-card-title {
      font-weight: 600;
      margin-top: 12px;
      font-size: 1.2rem;
    }
    .job-card-subtitle {
      color: var(--subtitle-color);
      font-size: 0.8rem;
      margin-top: 14px;
      line-height: 1.6em;
    }
    .job-detail-buttons {
      .detail-button {
        background-color: var(--color-bg);
        color: var(--color-blue);
        font-size: 11px;
        font-weight: 500;
        padding: 6px 8px;
        border-radius: 4px;
      }
      .search-buttons {
        border: none;
        background-color: var(--color-bg);
        color: var(--color-blue);
        padding: 8px 10px;
        border-radius: 6px;
        font-size: 11px;
        font-weight: 600;
        margin-top: 14px;
        margin-left: 4px;
      }
    }
    .job-card-buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-top: 4px;

      .card-buttons {
        margin-right: 12px;
      }
    }
  }
}
</style>
